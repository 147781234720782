import { Dialog } from "../Dialog";
import { useApp, useExcalidrawSetAppState } from "../App";
import MermaidToExcalidraw from "./MermaidToExcalidraw";
import TTDDialogTabs from "./TTDDialogTabs";
import { useEffect, useRef, useState } from "react";
import { useUIAppState } from "../../context/ui-appState";
import { withInternalFallback } from "../hoc/withInternalFallback";
import { TTDDialogTabTriggers } from "./TTDDialogTabTriggers";
import { TTDDialogTabTrigger } from "./TTDDialogTabTrigger";
import { TTDDialogTab } from "./TTDDialogTab";
import { t } from "../../i18n";
import { TTDDialogInput } from "./TTDDialogInput";
import { TTDDialogOutput } from "./TTDDialogOutput";
import { TTDDialogPanel } from "./TTDDialogPanel";
import { TTDDialogPanels } from "./TTDDialogPanels";
import type { MermaidToExcalidrawLibProps } from "./common";
import { saveMermaidDataToStorage } from "./common";
import type { ExcalidrawImperativeAPI } from "../../types";
import { ArrowRightIcon } from "../icons";

import "./TTDDialog.scss";
import { InlineIcon } from "../InlineIcon";
import { TTDDialogSubmitShortcut } from "./TTDDialogSubmitShortcut";
import { useTTDDialog } from "./useTTDDialog";


type OnTestSubmitRetValue = {
  rateLimit?: number | null;
  rateLimitRemaining?: number | null;
} & (
    | { generatedResponse: string | undefined; error?: null | undefined }
    | {
      error: Error;
      generatedResponse?: null | undefined;
    }
  );

export const TTDDialogCopy = (
  props:
    | {
      onTextSubmit(value: string): Promise<OnTestSubmitRetValue>;
      excalidrawAPI: ExcalidrawImperativeAPI | null;
      inputTextRef: any;
      buttonRef: any;
      insertButtonRef: any;
      showOnLoad: boolean;
    }
    | {
      __fallback: true;
      excalidrawAPI: ExcalidrawImperativeAPI | null;
      inputTextRef: any;
      buttonRef: any;
      insertButtonRef: any;
      showOnLoad: boolean;
    },
) => {
  // return null;
  const appState = useUIAppState();

  if (appState.openDialog?.name !== "ttd_copy") {
    return null;
  }

  return (
    <TTDDialogBase
      {...props}
      tab={(appState?.openDialog as unknown as any)?.tab}
      inputTextRef={props.inputTextRef}
      buttonRef={props.buttonRef}
      insertButtonRef={props.insertButtonRef}
      showOnLoad={props.showOnLoad}
    />
  );
};

/**
 * Text to diagram (TTD) dialog
 */
export const TTDDialogBase = withInternalFallback(
  "TTDDialogBase",
  ({
    tab,
    excalidrawAPI,
    inputTextRef,
    buttonRef,
    insertButtonRef,
    showOnLoad = false,
    ...rest
  }: {
    tab: "text-to-diagram" | "mermaid";
    excalidrawAPI: ExcalidrawImperativeAPI | null;
    inputTextRef: any;
    buttonRef: any;
    insertButtonRef: any;
    showOnLoad: boolean;
  } & (
      | {
        onTextSubmit(value: string): Promise<OnTestSubmitRetValue>;
      }
      | { __fallback: true }
    )) => {
    const app = useApp();
    const {
      text,
      prompt,
      error,
      rateLimits,
      handleTextChange,
      onGenerate,
      someRandomDivRef,
      // setError,
      // setText,
      // setRateLimits,
      onTextSubmitInProgress,
      ttdGeneration,
      handleAddElement,
      MAX_PROMPT_LENGTH,
    } = useTTDDialog({
      onTextSubmit: (rest as unknown as any).onTextSubmit as unknown as any,
      excalidrawAPI,
    });
    const setAppState = useExcalidrawSetAppState();

    const refOnGenerate = useRef(onGenerate);
    refOnGenerate.current = onGenerate;

    const [mermaidToExcalidrawLib, setMermaidToExcalidrawLib] =
      useState<MermaidToExcalidrawLibProps>({
        loaded: false,
        api: import("@excalidraw/mermaid-to-excalidraw"),
      });

    useEffect(() => {
      const fn = async () => {
        await mermaidToExcalidrawLib.api;
        setMermaidToExcalidrawLib((prev) => ({ ...prev, loaded: true }));
      };
      fn();
    }, [mermaidToExcalidrawLib.api]);

    // const data = useRef<{
    //   elements: readonly NonDeletedExcalidrawElement[];
    //   files: BinaryFiles | null;
    // }>({ elements: [], files: null });

    // const [error, setError] = useState<Error | null>(null);

    const handleCustomAddElement = async () => {
      handleAddElement();
      app.setOpenDialog(null);
    };

    return (
      <Dialog
        className="ttd-dialog hidden"
        onCloseRequest={() => {
          app.setOpenDialog(null);
        }}
        size={1200}
        title={false}
        {...rest}
        autofocus={false}
      >
        <TTDDialogTabs dialog="ttd" tab={tab}>
          {"__fallback" in rest && rest.__fallback ? (
            <p className="dialog-mermaid-title">{t("mermaid.title")}</p>
          ) : (
            <TTDDialogTabTriggers>
              <TTDDialogTabTrigger tab="text-to-diagram">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {"Text to image"}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1px 6px",
                      marginLeft: "10px",
                      fontSize: 10,
                      borderRadius: "12px",
                      background: "var(--color-promo)",
                      color: "var(--color-surface-lowest)",
                    }}
                  >
                    AI Beta
                  </div>
                </div>
              </TTDDialogTabTrigger>
              {/* <TTDDialogTabTrigger tab="mermaid">Mermaid</TTDDialogTabTrigger> */}
            </TTDDialogTabTriggers>
          )}

          <TTDDialogTab className="ttd-dialog-content" tab="mermaid">
            <MermaidToExcalidraw
              mermaidToExcalidrawLib={mermaidToExcalidrawLib}
            />
          </TTDDialogTab>
          {!("__fallback" in rest) && (
            <TTDDialogTab className="ttd-dialog-content" tab="text-to-diagram">
              <TTDDialogPanels>
                <TTDDialogPanel
                  buttonRef={buttonRef}
                  label={t("labels.prompt")}
                  panelAction={{
                    action: onGenerate,
                    label: "Generate",
                    icon: ArrowRightIcon,
                  }}
                  onTextSubmitInProgess={onTextSubmitInProgress}
                  panelActionDisabled={
                    prompt.length > MAX_PROMPT_LENGTH ||
                    rateLimits?.rateLimitRemaining === 0
                  }
                  renderTopRight={() => {
                    if (!rateLimits) {
                      return null;
                    }

                    return (
                      <div
                        className="ttd-dialog-rate-limit"
                        style={{
                          fontSize: 12,
                          marginLeft: "auto",
                          color:
                            rateLimits.rateLimitRemaining === 0
                              ? "var(--color-danger)"
                              : undefined,
                        }}
                      >
                        {rateLimits.rateLimitRemaining} requests left today
                      </div>
                    );
                  }}
                  renderSubmitShortcut={() => <TTDDialogSubmitShortcut />}
                  renderBottomRight={() => {
                    if (typeof ttdGeneration?.generatedResponse === "string") {
                      return (
                        <div
                          className="excalidraw-link"
                          style={{ marginLeft: "auto", fontSize: 14 }}
                          onClick={() => {
                            if (
                              typeof ttdGeneration?.generatedResponse ===
                              "string"
                            ) {
                              saveMermaidDataToStorage(
                                ttdGeneration.generatedResponse,
                              );
                              setAppState({
                                openDialog: {
                                  name: "ttd_copy",
                                  tab: "text-to-diagram",
                                },
                              });
                            }
                          }}
                        >
                          View as Mermaid
                          <InlineIcon icon={ArrowRightIcon} />
                        </div>
                      );
                    }
                    const ratio = prompt.length / MAX_PROMPT_LENGTH;
                    if (ratio > 0.8) {
                      return (
                        <div
                          style={{
                            marginLeft: "auto",
                            fontSize: 12,
                            fontFamily: "monospace",
                            color:
                              ratio > 1 ? "var(--color-danger)" : undefined,
                          }}
                        >
                          Length: {prompt.length}/{MAX_PROMPT_LENGTH}
                        </div>
                      );
                    }

                    return null;
                  }}
                >
                  <TTDDialogInput
                    onChange={handleTextChange}
                    input={text}
                    placeholder={"Describe what you want to see..."}
                    onKeyboardSubmit={() => {
                      refOnGenerate.current();
                    }}
                    inputTextRef={inputTextRef}
                  />
                </TTDDialogPanel>
                <TTDDialogPanel
                  label="Preview"
                  buttonRef={insertButtonRef}
                  panelAction={{
                    action: () => {
                      console.info("Panel action clicked");
                      handleCustomAddElement();
                    },
                    label: "Insert",
                    icon: ArrowRightIcon,
                  }}
                >
                  <TTDDialogOutput
                    canvasRef={someRandomDivRef}
                    error={error}
                    loaded
                    generatedResponse={
                      ttdGeneration?.generatedResponse as string
                    }
                  />
                </TTDDialogPanel>
              </TTDDialogPanels>
            </TTDDialogTab>
          )}
        </TTDDialogTabs>
      </Dialog>
    );
  },
);
