import "./ExcalidrawLogo.scss";

type LogoSize = "xs" | "small" | "normal" | "large" | "custom";

interface LogoProps {
  size?: LogoSize;
  withText?: boolean;
  style?: React.CSSProperties;
  customStyle?: string;
  /**
   * If true, the logo will not be wrapped in a Link component.
   * The link prop will be ignored as well.
   * It will merely be a plain div.
   */
  isNotLink?: boolean;
}

export const ExcalidrawLogo = ({
  style,
  size = "small",
  withText,
  customStyle,
}: LogoProps) => {
  return (
    <div className={`ExcalidrawLogo ${customStyle} is-${size}`} style={style}>
      <img src={"https://gpt.share.vc/assets/favicon-32x32.png"} />
      {withText && "Share OS"}
    </div>
  );
};
