import { useState, useEffect, useRef } from "react";
import { atom, useAtom } from "jotai";
import { useExcalidrawSetAppState } from "../App";
import type { MermaidToExcalidrawLibProps } from "./common";
import { convertMermaidToExcalidraw } from "./common";
import type {
  FileId,
  NonDeletedExcalidrawElement,
  OrderedExcalidrawElement,
} from "../../element/types";
import { trackEvent } from "../../analytics";
import type { BinaryFiles, ExcalidrawImperativeAPI } from "../../types";
import { restoreElements } from "../../data/restore";

const ttdGenerationAtom = atom<{
  generatedResponse: string | null;
  prompt: string | null;
} | null>(null);

const rateLimitsAtom = atom<{
  rateLimit: number;
  rateLimitRemaining: number;
} | null>(null);

const MIN_PROMPT_LENGTH = 3;
const MAX_PROMPT_LENGTH = 1000;

export const useTTDDialog = ({
  onTextSubmit,
  excalidrawAPI,
}: {
  onTextSubmit: (val1: string, val2: string) => Promise<any>;
  excalidrawAPI: ExcalidrawImperativeAPI | null;
}) => {
  const setAppState = useExcalidrawSetAppState();

  const someRandomDivRef = useRef<HTMLDivElement>(null);

  const [ttdGeneration, setTtdGeneration] = useAtom(ttdGenerationAtom);
  const [text, setText] = useState(ttdGeneration?.prompt ?? "");
  const prompt = text.trim();

  const [onTextSubmitInProgress, setOnTextSubmitInProgress] = useState(false);
  const [rateLimits, setRateLimits] = useAtom(rateLimitsAtom);
  const [selectedOption, setSelectedOption] = useState("");

  const [mermaidToExcalidrawLib, setMermaidToExcalidrawLib] =
    useState<MermaidToExcalidrawLibProps>({
      loaded: false,
      api: import("@excalidraw/mermaid-to-excalidraw"),
    });

  const [error, setError] = useState<Error | null>(null);
  const data = useRef<{
    elements: readonly NonDeletedExcalidrawElement[];
    files: BinaryFiles | null;
  }>({ elements: [], files: null });

  useEffect(() => {
    const fn = async () => {
      await mermaidToExcalidrawLib.api;
      setMermaidToExcalidrawLib((prev) => ({ ...prev, loaded: true }));
    };
    fn();
  }, [mermaidToExcalidrawLib.api]);

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setText(event.target.value);
    setTtdGeneration((s) => ({
      generatedResponse: s?.generatedResponse ?? null,
      prompt: event.target.value,
    }));
  };

  const onGenerate = async (addAuto?: boolean) => {
    if (
      prompt.length > MAX_PROMPT_LENGTH ||
      prompt.length < MIN_PROMPT_LENGTH ||
      onTextSubmitInProgress ||
      rateLimits?.rateLimitRemaining === 0
    ) {
      if (prompt.length < MIN_PROMPT_LENGTH) {
        setError(
          new Error(
            `Prompt is too short (min ${MIN_PROMPT_LENGTH} characters)`,
          ),
        );
      }
      if (prompt.length > MAX_PROMPT_LENGTH) {
        setError(
          new Error(`Prompt is too long (max ${MAX_PROMPT_LENGTH} characters)`),
        );
      }
      return;
    }

    try {
      setOnTextSubmitInProgress(true);
      trackEvent("ai", "generate", "ttd");

      const { generatedResponse, error, rateLimit, rateLimitRemaining } =
        await onTextSubmit(prompt, selectedOption);

      if (typeof generatedResponse === "string") {
        setTtdGeneration((s) => ({
          generatedResponse,
          prompt: s?.prompt ?? null,
        }));
      }

      if (isFinite(rateLimit) && isFinite(rateLimitRemaining)) {
        setRateLimits({ rateLimit, rateLimitRemaining });
      }

      if (error) {
        setError(error);
        return;
      }
      if (!generatedResponse) {
        setError(new Error("Generation failed"));
        return;
      }

      try {
        await convertMermaidToExcalidraw({
          canvasRef: someRandomDivRef,
          data,
          mermaidToExcalidrawLib,
          setError,
          mermaidDefinition: generatedResponse,
        });
        trackEvent("ai", "mermaid parse success", "ttd");
        if (addAuto) {
          handleAddElement(generatedResponse);
        }
      } catch (error: any) {
        trackEvent("ai", "mermaid parse failed", "ttd");
        setError(null);
      }
    } catch (error: any) {
      setError(new Error(error.message || "Request failed"));
    } finally {
      setOnTextSubmitInProgress(false);
    }
  };

  let pikaDataURL: any = null;
  let currImageX = 200;

  const handleAddElement = async (generatedResponse?: string) => {
    pikaDataURL = generatedResponse || ttdGeneration?.generatedResponse;

    const id = `${Math.random()}-icon`;

    excalidrawAPI?.addFiles([
      {
        id: `${id}` as FileId,
        dataURL: pikaDataURL,
        mimeType: "image/jpeg",
        created: 100,
      },
    ]);

    currImageX += 100;
    excalidrawAPI?.updateScene({
      elements: restoreElements(
        [
          ...excalidrawAPI.getSceneElementsIncludingDeleted(),
          {
            fileId: `${id}` as FileId,
            type: "image",
            status: "saved",
            x: currImageX,
            y: 300,
            width: 100,
            height: 100,
            backgroundColor: "",
          },
        ] as OrderedExcalidrawElement[],
        null,
        undefined,
      ),
    });
  };

  return {
    text,
    prompt,
    error,
    rateLimits,
    onTextSubmitInProgress,
    handleTextChange,
    onGenerate,
    setSelectedOption,
    someRandomDivRef,
    mermaidToExcalidrawLib,
    setAppState,
    data,
    setError,
    setText,
    setRateLimits,
    ttdGeneration,
    handleAddElement,
    MIN_PROMPT_LENGTH,
    MAX_PROMPT_LENGTH,
  };
};
